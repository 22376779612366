@import url('https://fonts.cdnfonts.com/css/baloo-paaji');

.nav-container {
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1;
    margin: 0;
    border-radius: 0px;
    padding-top: 2em;
    transition: background-color 0.2s ease-in-out; 
}

.nav-flex-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 95%;
    margin: 0 auto; /* Centers the container */
}

.show-bg {
    background-image: radial-gradient(circle at center, var(--secondary) -500%, var(--tertiary) 90%);
}


.nav-items-container {
    display: flex;
    gap: 15px;
}

.nav-items-container a {
    padding-left: 0.5em;
    color: var(--secondary);
    font-family: 'Baloo Paaji', cursive;
    font-size: 2.3em;
    text-decoration: none;
}

.nav-items-container a:hover {
    color: var(--primary);
}

/* For medium screens (tablets) */
@media (max-width: 991px) {
    .nav-items-container a {
        font-size: 1.8em;
    }
}

/* For small screens (large phones) */
@media (max-width: 767px) {
    .nav-items-container a {
        font-size: 2em;
    }
}

/* For extra small screens (small phones) */
@media (max-width: 575px) {
    .nav-items-container a {
        font-size: 1.5em;
    }
}
