@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.cdnfonts.com/css/open-sans');
:root {
  --primary: #FFFFFF; 
  /* #00494A */
  --secondary: #FED000;
  --tertiary: #2C353B;
  --card: #1F4A72;
  --primary-font: 'Poppins', sans-serif;
  --spacing: 15px;
  --app-background-color: radial-gradient(circle at center, var(--secondary) -500%, var(--tertiary) 90%);
}

code {
  font-family: var(--primary-font);

}


body {
	background: var(--tertiary);
  background: var(--app-background-color);
	height: 95vh;
  background-size: cover;
  font-family: var(--primary-font);
}

.pageTitle {
  color: var(--secondary);
  font-size: 36px;
  border-bottom: 3px solid var(--hl-color);
  margin-bottom: var(--spacing);

  color: var(--secondary);
  font-size: 36px;
  text-decoration: underline;
  text-decoration-thickness: 4px;
  text-underline-offset: 10px;
}

.pageSubTitle {
  color: var(--secondary);
  font-size: 24px;
  /* border-bottom: 3px solid var(--hl-color); */
  margin-bottom: var(--spacing);
}

.pageDescription {
  color: var(--secondary);
  font-size: 16px;
}

.btn {
  color: #f9f9f9;
  background-color: var(--hl-color);
  border: none;
  font-size: 14px;
  padding: 12px var(--sm-spacing);
  border-radius: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--hov-color);
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}








