.contact-container {
  display: flex;
  justify-content: flex-end; /* Make sure items are pushed to the end (right) */
  align-items: baseline;
  flex-wrap: wrap; /* Enable wrapping on smaller screens */
}

.contact-text-content {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end; 
  order: 1;
  margin-right: 2rem; /* Add right margin to the text content */
  margin-left: 2em;
}

.contact-form {
  flex: 2.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  order: 2;
  margin-left: 2rem; /* Add left margin to the form */
}

.contact-text-content > h1, p {
    color: var(--secondary);
    font-family: 'Poppins', sans-serif;
}

.contact-text-content > h1 {
    font-size: calc((2.8 - 1) * 1.2vw + 1rem);
}

.contact-text-content > p {
    font-size: calc((0.2) * 1.2vw + 1rem);
}

.toast {
   margin-top: 70px !important; 
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn 0.5s forwards;
}

.fadeOut {
  animation: fadeOut 0.5s forwards;
}

/* Responsive handling */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }
  .contact-text-content {
    align-items: center; 
    margin-inline: auto;
    order: 0; /* Reset the order */
    margin-top: -6em;
  }
  .contact-form {
    order: 1; /* Reset the order */
    align-items: center; /* Center align form on smaller screens */
    margin-top: 2em;
  }

  .Typewriter { /* Assuming Typewriter is the correct class for "Let's connect" text */
    text-align: center; /* Centers the text horizontally */
    width: 100%; /* Ensures the text container takes full width */
    order: 0; /* Ensure the text appears in the desired order */
  }
}

.hide-cursor .Typewriter__cursor {
  display: none;
}
