@import url('https://fonts.cdnfonts.com/css/inconsolata-2');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.container-fluid {
    display: flex;
    flex-direction: column;
    align-items: center;     /* Center children horizontally */
    position: absolute;
    width: 90%;             /* Take full width of its parent */
    justify-content: center; /* Center children vertically */
    height: 70%;            /* Take full height of its parent */
}

.header-section {
    width: 100%;             /* Take the full width */
    display: flex;
    justify-content: center; /* Center the inner profile-container */
    align-items: center;
}

.profile-container {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center the content horizontally */
    align-items: center;
    position: relative;  
    left: 4em; /*Maybe?*/
    gap: 1em;
}

.button-section {
    width: 100%;             /* Take the full width */
    display: flex;
    justify-content: center; /* Center the button */
    padding-top: 2em;      
    position: relative;
    left: 4em
  }

hr {
    border-width: 0.3em;
    width: 15em;
    position: absolute;
    margin: auto;
    left: 50%;
    margin-left: -7.5em;  
    margin-top: 2em;
}

.profile-picture {
    width: 30em;
    height: 30em;
    border-radius: 28%;
    overflow: hidden;
    background-color: var(--secondary);
    border-color: white;
    border-width: 1em;
    border-style: solid;
    box-shadow: 0px 0px 20px rgba(3, 3, 3, 0.3);
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-header {
    flex: 2;
    color: var(--secondary);
    font-size: calc((2.8 - 1) * 1.2vw + 1rem);
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
}

#resume-button{
    width: 10em;
    height: 4em;
    /* left: -7.5; */
    font-weight: 900;
    font-size: 15px;
    background-color: var(--secondary);
}

@media (max-width: 768px) {
  .profile-container {
    flex-direction: column;  /* Change direction to column for stacking */
    align-items: center;     /* Center items horizontally */
    margin-right: 0;        /* Reset right margin */
    margin-left: -5em;         /* Reset left margin */
    gap: 1em;               /* Adjust gap between image and text */
  }

  #resume-button{
   margin-left: -5em;         /* Reset left margin */
  }

  .profile-picture {
    width: 15em;            /* Adjust width for small screens */
    height: 15em;           /* Adjust height for small screens */
  }

  .profile-header {
    text-align: center;     /* Center text on small screens */
    font-size: 1em;
    width: 80%;
  }
}

@media (max-width: 425px) {
  .profile-picture {
    width: 12em;            /* Adjust width for small screens */
    height: 12em;           /* Adjust height for small screens */
  }
  .profile-container {
    flex-direction: column;  /* Change direction to column for stacking */
    align-items: center;     /* Center items horizontally */
    margin-left: -8em;         /* Reset left margin */
    width: 60%;
  }

  .button-section{
    width: 60%
  }

  #resume-button{
    margin-left: -8em;         /* Reset left margin */
  }
}