
body {
  font-family: 'Poppins', sans-serif;
}

.project-title {
    font-size: 3.5em;
    color: var(--secondary);
    font-family: 'Baloo Paaji', cursive;
}

.project-desc {
    border-radius: 2em;
}

.closeMenu {
  height: 20px;
  transition: transform 0.5s ease-in-out;
}

#projects-header-text {
    color: var(--secondary);
    font-family: 'Baloo Paaji', cursive;
}

#projects-header-divider {
    width: 51em;
    border-radius: 0.3em;
    border: 0.25em solid;
}

#projects-header {
    margin-left: 22em;
    padding: 0px;
    display: flex;
    align-items: center;
}

.image-container {
    display: inline-block;
    position: relative;
}

.image-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 102, 0.05);
    border-radius: var(--profile-pic-border-radius);
    border-color: var(--secondary);
    border-style: solid;
    border-width: 0.4em;
    z-index: 1;
}

.project-img img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: var(--profile-pic-border-radius);
    object-fit: cover;
}

.list-stack-container {
    margin-block: 1em;
}

.logo-pair {
    margin-left: 0.4em;
}


@media (max-width: 768px) {
  /* Move hr to the top and adjust other properties */
  #projects-header {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1em; /* Adjust margin for smaller screens */
  }
  
  #projects-header-divider {
      display: none;
  }
  
  /* Stack the description above the image */
  .project-desc, .project-img {
    order: -1;
  }

  /* Adjustments to the titles and project descriptions */
  .project-title {
    font-size: 1em; /* Adjust to your preference */
  }

  #projects-header-text {
    font-size: 2em; /* Adjust to your preference */
  }

  .project-desc h1 {
    font-size: 1.5em; /* Adjust to your preference */
  }
}



/* --- MOBILE STYLES --- */

.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding-bottom: var(--spacing);
  margin: 0 auto; 
}


.projectCard {
  flex-basis: calc(33.333% - 40px); /* Adjust the basis and subtract twice the gap size */
  max-width: 100%; /* Adjust the max-width as well */
  margin-bottom: 20px;
  color: var(--primary);
  border-radius: 3px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
  flex-direction: column;
  padding: 0 !important;
  cursor: pointer;
  width: 60em;
  height: 27em !important;
  z-index: -9;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.spacing_bottom { 
  margin-bottom: var(--spacing);
}

.textWrap {
  transition: all 0.9s ease-in-out;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the items horizontally in the container */
  justify-content: center; 
  align-items: center;
  width: 40%;
}

.projectLink {
  text-decoration: none;
}

.imageContainer img {
  height: 12em;
  margin-block: 25px;
  margin-inline: 25px;
  transition: all 1.3s ease-in-out;
}

.tech {
  font-weight: thin !important;
  font-size: 16px !important;
  /* white-space: nowrap; */
  width: 100%;
  color: var(--primary);
}

.viewWork {
  font-size: 14px;
}

.projectTitle {
  font-size: 24px;
  padding: 10px 0 !important;
  justify-content: center;
}

.githubLink {
  text-decoration: none;
  margin-top: 3em;
}

.githubLink:hover {
  color: white;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.projectCard::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}

.closeModal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.closeModal:hover {
  filter: brightness(100);
}

.modalTitle {
  text-align: center;
  margin-bottom: 10px;
  color: white;
}

.projectDescription {
  margin-bottom: 10px;
  color: #9f9f9f;
  ydisplay: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.carousel-container {
  /* Adjust the max-height value as per your design requirements */
  height: 80%;
  width: 95%;
  margin: auto;

}

.carousel-item {
  max-height: 500px;
  display: flex; /* Use Flexbox */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

.carousel-image {
  display: block;
  max-height: 500px; /* 100% of .carousel-item height */
  max-width: 100%;  /* 100% of .carousel-item width */
  margin: auto;
  object-fit: contain; /* Cover the area of the container */
  margin-bottom: 2em;
}

.slick-dots li button {
  background: white !important;
}


/* --- MEDIA QUEIRES --- */

@media(max-width: 1440px) {
  .portfolio {
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center the portfolio horizontally */
    height: 100vh; /* This makes sure that the portfolio takes the full height of the viewport */
  }
  
  .row {
    width: 100%;
    justify-content: center; /* This will center the project cards within the row */
    flex-wrap: wrap; /* This allows the cards to wrap when the screen gets too narrow */
  }

  .projectCard {
    padding-top: 6em !important;
    width: auto; /* This allows the card to grow with the content inside */
    max-width: 90%; /* This prevents the card from growing beyond the width of its container */
    margin: 0 auto; /* This will center the card if it has a max-width */
    margin-bottom: 5px;
  }
}


@media (min-width: 750px) {
  .projectCard {
    flex-direction: row;
    padding: var(--sm-spacing) !important;
    height: 350px;
    /* width: 300px; */
  }

  .portfolio {
    padding-left: var(--spacing);
    padding-right: var(--spacing);
  }

  .imageContainer {
    transform: translateX(30%);
    transition: all 0.3s ease-in-out;
  }

  .projectCard:hover .imageContainer {
    transform: translateX(-70%);
  }

  .projectCard:hover .textWrap {
    transform: translateX(-250%);
  }

  .projectCard:hover::before {
    opacity: 0;
  }

  .projectCard:hover .imageContainer img {
    scale: 1.4;
  }

  .imageContainer {
    transform: translateX(30%);
    transition: all 0.3s ease-in-out;
  }

  .textWrap {
    text-shadow: none;
  }

  .projectTitle {
    font-size: 30px;
    padding: 10px 0;
  }

  .viewWork {
    font-size: 16px;
  }

}

@media(max-width: 425px) {
  .pageTitle {
    display: none;
  }

  .tech{
    font-size: 12px !important;
  }
}